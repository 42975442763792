import React from "react";
import styled from "styled-components";

// Styles
import { borderColor } from "../styles/colors";

// Components
import Mailchimp from "../mailchimp/mailchimp";
import TextBox from "../text/text-box";

const BasicColumn = styled.div`
  & .split-column {
    & > div {
      width: fit-content;
      column-count: 2;
      column-width: 200px;
      column-gap: 30px;
      margin: 0 0 0 auto;
    }

    padding: 0 20px 0 0;

    order: 1;

    @media (max-width: 1220px) {
      max-width: 100%;
      display: block;
    }

    @media (max-width: 1025px) {
      column-count: unset;
      column-width: unset;

      max-width: 300px;
      width: 100%;

      padding: 0 0 0 40px;
    }

    @media (max-width: 1025px) {
      padding: 0;

      & p:last-of-type {
        margin-bottom: 0;
      }
    }

    @media (max-width: 640px) {
      padding: 0;
    }
  }

  & p {
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
  }

  & p:first-of-type {
    margin-top: 0;
  }
`;

const ListColumn = styled(BasicColumn)`
  width: 100%;
  max-width: calc(100% - 600px);

  @media (max-width: 1025px) {
    max-width: 100%;
  }

  & .text-box {
    max-width: 300px;
  }
`;

const InnerColumn = styled(BasicColumn)`
  width: 100%;
  max-width: 276px;

  @media (max-width: 1025px) {
    & p:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const Flex = styled.div`
  display: block;

  padding: 0;
  margin: 0;
`;

const MobileSpacer = styled.hr`
  order: ${props => props.order};
  display: block;

  margin: ${props => (props.mobileMargin ? props.mobileMargin : `30px -20px`)};
  padding: 0;

  border: 0;
  border-style: solid;
  border-bottom: 1px solid ${borderColor};

  width: 100vw;

  @media (max-width: 500px) {
    margin: ${props =>
      props.mobileMargin ? props.mobileMargin : `30px -10px`};
  }
`;

export const MobileStudioList = ({ data }) => (
  <ListColumn>
    <Flex>
      <MobileSpacer />

      <div className="text-box">
        <TextBox
          fontSize={`15px`}
          lineHeight={`20px`}
          text={data.prismicStudio.data.studio_contact}
        />
      </div>
      <div className="text-box">
        <Mailchimp />
      </div>
      <MobileSpacer mobileMargin={`30px -20px`} />

      <div className="text-box">
        <TextBox
          fontSize={`15px`}
          lineHeight={`20px`}
          text={data.prismicStudio.data.studio_list}
        />
      </div>
      <MobileSpacer />

      <InnerColumn>
        <div className="text-box">
          <TextBox
            fontSize={`15px`}
            lineHeight={`20px`}
            text={data.prismicStudio.data.internship_text}
          />
        </div>
        <div className="text-box">
          <br />
          <TextBox
            fontSize={`15px`}
            lineHeight={`20px`}
            text={data.prismicStudio.data.bottom_text}
          />
        </div>
      </InnerColumn>
    </Flex>
  </ListColumn>
);
