import React from "react";
import addToMailchimp from "gatsby-plugin-mailchimp";
import styled from "styled-components";
import Arrow from "../icons/arrow";

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  margin: 0 0 15px 0;
  box-sizing: content-box;

  line-height: 31px;

  & input {
    width: calc(100% - 36px - 20px);
    height: 28px;

    font-family: starling, serif;
    font-size: 14px;

    padding: 0 0 0 10px;

    background-color: transparent;
    -webkit-appearance: none;

    border: 1px solid #333333;

    &:focus {
      outline: none;
    }

    position: relative;
    line-height: 1;
  }
`;

const MailchimpFormContainer = styled.div`
  margin-top: 1em;

  font-size: 15px;
  line-height: 20px;

  @media (max-width: 500px) {
    max-width: 100%;
    width: 100%;
  }

  & input {
    ::-webkit-input-placeholder {
      color: #333;
      font-family: starling, serif;
      line-height: 25px;
    }
    ::-moz-placeholder {
      color: #333;
      font-family: starling, serif;
      line-height: 25px;
    }
    :-ms-input-placeholder {
      &color: #333;
      font-family: starling, serif;
      line-height: 25px;
    }
    :-moz-placeholder {
      color: #333;
      font-family: starling, serif;
      line-height: 25px;
    }
    ::placeholder {
      color: #333;
      font-family: starling, serif;
      line-height: 25px;
    }
  }
`;

const ThankYouMessage = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  width: calc(100% - 36px - 20px);
  height: 28px;

  border: 1px solid rgb(51, 51, 51);

  margin: 0 0 15px 0;
  box-sizing: content-box;

  font-family: starling, serif;
  font-size: 15px;

  padding: 0 0 0 10px;
  margin: 0;

  background-color: transparent;
  -webkit-appearance: none;

  position: relative;
  line-height: 1;
`;

const SignUpButton = styled.button`
  font-family: starling, serif;
  line-height: 31px;

  -webkit-appearance: none;
  letter-spacing: 0.3px;

  width: 32px;
  height: 32px;
  border-radius: 100%;
  border: 1px solid #333;

  position: relative;

  cursor: pointer;

  &:focus {
    outline: none;
  }
  transition: all 0 ease-in-out;

  &:hover {
    color: #fff;
    background-color: #333;
  }

  & svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    & path {
      fill: #000;
    }
  }

  &:hover svg path {
    fill: #fff;
  }
`;

class Mailchimp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      EMAIL: null,
      message: null,
    };

    this.submitMailchimp = this.submitMailchimp.bind(this);
  }

  handleChangeMailchimp = e => {
    this.setState({
      [`${e.target.name}`]: e.target.value,
    });
  };

  submitMailchimp = e => {
    e.preventDefault();

    addToMailchimp(this.state.EMAIL)
      .then(({ msg, result }) => {
        if (result !== "success") {
          throw msg;
        }
        this.setState({
          message: "Thank you for subscribing",
        });
      })
      .catch(err => {
        this.setState({
          message: "This email is already subscribed",
        });
        console.log("err", err);
      });
  };

  render(props) {
    return (
      <MailchimpFormContainer>
        <em>Newsletter</em>
        {this.state.message === null && (
          <form onSubmit={this.submitMailchimp}>
            <InputContainer>
              <input
                type="email"
                onChange={this.handleChangeMailchimp}
                name="EMAIL"
              />

              <SignUpButton type="submit" disabled={this.state.EMAIL === null}>
                <Arrow />
              </SignUpButton>
            </InputContainer>
          </form>
        )}
        {this.state.message !== null && (
          <InputContainer>
            <ThankYouMessage>
              <em>{this.state.message}</em>
            </ThankYouMessage>
            <SignUpButton type="submit" disabled>
              <Arrow />
            </SignUpButton>
          </InputContainer>
        )}
      </MailchimpFormContainer>
    );
  }
}

export default Mailchimp;
