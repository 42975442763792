import React, { useMemo, useState, useEffect } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { mergePrismicPreviewData } from "gatsby-source-prismic";
import Media from "react-media";
import { Helmet } from "react-helmet";

// Components
import ThreeTwo from "../components/images/three-two";
import TextBox from "../components/text/text-box";

import { DesktopStudioList } from "../components/studio/desktop-studio-list";
import { TwoColumnStudioList } from "../components/studio/two-column-studio-list";
import { MobileStudioList } from "../components/studio/mobile-studio-list.js";

const Page = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: space-between;

  padding: 60px 20px 5px 20px;

  @media (max-width: 1025px) {
    padding: 60px 20px 30px 20px;
  }

  @media (max-width: 500px) {
    padding: 46px 10px 30px 10px;
  }
`;

const BasicColumn = styled.div`
  & .split-column {
    & > div {
      width: fit-content;
      column-count: 2;
      column-width: 200px;
      column-gap: 30px;
      margin: 0 0 0 auto;
    }

    padding: 0 20px 0 0;

    order: 1;

    @media (max-width: 1220px) {
      order: 10;

      max-width: 100%;
      display: block;
    }

    @media (max-width: 1025px) {
      column-count: unset;
      column-width: unset;

      max-width: 300px;
      width: 100%;

      padding: 0 0 0 40px;
    }

    @media (max-width: 1025px) {
      padding: 0;

      & p:last-of-type {
        margin-bottom: 0;
      }
    }

    @media (max-width: 640px) {
      padding: 0;
    }
  }

  & p {
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
  }

  & p:first-of-type {
    margin-top: 0;
  }
`;

const AboutColumn = styled(BasicColumn)`
  width: 100%;
  max-width: 600px;

  margin: 0 0 30px 0;

  & p {
    margin: 0;
  }

  @media (max-width: 1025px) {
    margin: 0;
  }
`;

const ImageContainer = styled.div`
  margin-bottom: 30px;

  @media (max-width: 1025px) {
    margin-bottom: 12px;
  }
`;

// Returns true if we're in a browser, false otherwise. This will help guard
// against SSR issues when building the site.
const IS_BROWSER = typeof window !== "undefined";

const Studio = ({ data: staticData }) => {
  const data = useMemo(() => {
    // If we're not in a browser (i.e. we're in SSR) or preview data has not been
    // set, use the non-preview static data.
    if (!IS_BROWSER || !window.__PRISMIC_PREVIEW_DATA__) return staticData;

    return mergePrismicPreviewData({
      staticData,
      previewData: window.__PRISMIC_PREVIEW_DATA__,
    });
  }, [staticData]);

  const [windowWidth, setWindowWidth] = useState(768);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      let getWindowWidth = window.innerWidth;
      setWindowWidth(getWindowWidth);
    }
  }, []);

  console.log(data.prismicStudio.data.studio_image.url);

  return (
    <>
      <Helmet
        meta={[
          {
            name: "og:url",
            content: `https://www.wolfehall.com/studio/`,
          },
        ]}
      />
      <Page>
        <AboutColumn>
          {data.prismicStudio.data.studio_image.url !== undefined && (
            <ImageContainer>
              <ThreeTwo
                image={data.prismicStudio.data.studio_image.url}
                alt={data.prismicStudio.data.studio_image.alt}
                lazy={false}
                size={600}
                objectFit={`cover`}
              />
            </ImageContainer>
          )}

          <TextBox
            fontSize={`19px`}
            lineHeight={`25px`}
            mobileFontSize={`17px`}
            mobileLineHeight={`22px`}
            text={data.prismicStudio.data.studio_text}
          />
        </AboutColumn>
        <Media
          queries={{ medium: "(max-width: 1025px)" }}
          defaultMatches={{ medium: windowWidth === 1025 }}
          render={() => <MobileStudioList data={data} />}
        />

        <Media
          queries={{ medium: "(max-width: 1219px)" }}
          defaultMatches={{ medium: windowWidth === 1219 }}
          render={() => <TwoColumnStudioList data={data} />}
        />

        <Media
          queries={{ medium: "(min-width: 1220px)" }}
          defaultMatches={{ medium: windowWidth === 1220 }}
          render={() => <DesktopStudioList data={data} />}
        />
      </Page>
    </>
  );
};

export default Studio;

export const pageQuery = graphql`
  query Studio {
    prismicStudio {
      data {
        studio_text {
          html
        }
        studio_list {
          html
        }
        studio_image {
          alt
          copyright
          url
        }
        studio_contact {
          html
        }
        internship_text {
          html
        }
        bottom_text {
          html
        }
      }
    }
  }
`;
