import React from "react";
import styled from "styled-components";

const Icon = styled.svg``;

const Arrow = () => (
  <Icon
    width="21"
    height="16"
    viewBox="0 0 21 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12.8511 15.7289L20.24 8.28441L12.8511 0.839966L12.4344 1.20108L16.4622 7.78441H0.240002V8.78441H16.4622L12.4344 15.3677L12.8511 15.7289Z" />
  </Icon>
);

export default Arrow;
