import React from "react";
import styled from "styled-components";

// Styles
import { borderColor } from "../styles/colors";

// Components
import Mailchimp from "../mailchimp/mailchimp";
import TextBox from "../text/text-box";

const BasicColumn = styled.div`
  & p {
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
  }

  & p:first-of-type {
    margin-top: 0;
  }

  width: 100%;
  max-width: calc(100% - 600px);

  .mailchimp {
    width: 100%;
  }
  @media (max-width: 1025px) {
    display: none;
  }
`;

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  padding: 0 0 0 40px;

  @media (max-width: 1220px) {
    flex-wrap: wrap;

    max-width: 315px;
    margin: 0 0 0 auto;
  }

  @media (max-width: 1025px) {
    justify-content: flex-start;

    max-width: 100%;

    padding: 0;
    margin: 0;
  }
`;

const BottomText = styled.div`
  @media (max-width: 1025px) {
    display: none;
  }
`;

export const TwoColumnStudioList = ({ data }) => (
  <BasicColumn>
    <Flex>
      <TextBox
        fontSize={`15px`}
        lineHeight={`20px`}
        text={data.prismicStudio.data.studio_contact}
        className="company-info"
      />

      <div className="mailchimp">
        <Mailchimp />
      </div>

      <TextBox
        fontSize={`15px`}
        lineHeight={`20px`}
        text={data.prismicStudio.data.studio_list}
      />

      <TextBox
        className="internships"
        fontSize={`15px`}
        lineHeight={`20px`}
        text={data.prismicStudio.data.internship_text}
      />

      <TextBox
        fontSize={`15px`}
        lineHeight={`20px`}
        text={data.prismicStudio.data.bottom_text}
      />
    </Flex>
  </BasicColumn>
);
