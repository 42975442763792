import React from "react";
import styled from "styled-components";

// Styles
import { borderColor } from "../styles/colors";

// Components
import Mailchimp from "../mailchimp/mailchimp";
import TextBox from "../text/text-box";

const BasicColumn = styled.div`
  & .split-column {
    & > div {
      width: fit-content;
      column-count: 2;
      column-width: 200px;
      column-gap: 30px;
      margin: 0 0 0 auto;
    }

    padding: 0 20px 0 0;

    order: 1;

    @media (max-width: 1220px) {
      order: 10;

      max-width: 100%;
      display: block;
    }

    @media (max-width: 1025px) {
      column-count: unset;
      column-width: unset;

      max-width: 300px;
      width: 100%;

      padding: 0 0 0 40px;
    }

    @media (max-width: 1025px) {
      padding: 0;

      & p:last-of-type {
        margin-bottom: 0;
      }
    }

    @media (max-width: 640px) {
      padding: 0;
    }
  }

  & p {
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
  }

  & p:first-of-type {
    margin-top: 0;
  }
`;

const AboutColumn = styled(BasicColumn)`
  width: 100%;
  max-width: 600px;

  margin: 0 0 30px 0;

  & p {
    margin: 0;
  }

  @media (max-width: 1025px) {
    margin: 0;
  }
`;

const ListColumn = styled(BasicColumn)`
  width: 100%;
  max-width: calc(100% - 600px);

  @media (max-width: 1025px) {
    max-width: 100%;
  }
`;

const InnerColumn = styled(BasicColumn)`
  width: 100%;
  max-width: 276px;

  order: 10;

  @media (max-width: 1220px) {
    order: 1;
    max-width: 100%;
  }

  @media (max-width: 1025px) {
    order: 10;
    max-width: 300px;
  }

  @media (max-width: 1025px) {
    & p:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const ImageContainer = styled.div`
  margin-bottom: 30px;

  @media (max-width: 1025px) {
    margin-bottom: 12px;
  }
`;

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  padding: 0 0 0 40px;

  @media (max-width: 1220px) {
    flex-wrap: wrap;

    max-width: 315px;
    margin: 0 0 0 auto;
  }

  @media (max-width: 1025px) {
    justify-content: flex-start;

    max-width: 100%;

    padding: 0;
    margin: 0;
  }
`;

const MobileSpacer = styled.hr`
  display: none;

  @media (max-width: 1025px) {
    order: ${props => props.order};
    display: block;

    margin: ${props =>
      props.mobileMargin ? props.mobileMargin : `30px -20px`};
    padding: 0;

    border: 0;
    border-style: solid;
    border-bottom: 1px solid ${borderColor};

    width: 100vw;
  }

  @media (max-width: 500px) {
    margin: ${props =>
      props.mobileMargin ? props.mobileMargin : `30px -10px`};
  }
`;

const BottomText = styled.div`
  @media (max-width: 1025px) {
    display: none;
  }
`;

const MobileBottomText = styled.div`
  display: none;

  @media (max-width: 1025px) {
    display: block;
  }
`;

export const DesktopStudioList = ({ data }) => (
  <ListColumn>
    <Flex>
      <MobileSpacer order={1} />
      <div className="split-column">
        <TextBox
          fontSize={`15px`}
          lineHeight={`20px`}
          text={data.prismicStudio.data.studio_list}
        />
        <MobileSpacer mobileMargin={`30px -20px`} />
        <MobileBottomText>
          <TextBox
            fontSize={`15px`}
            lineHeight={`20px`}
            text={data.prismicStudio.data.bottom_text}
          />
        </MobileBottomText>
      </div>
      <MobileSpacer order={3} />

      <InnerColumn>
        <TextBox
          fontSize={`15px`}
          lineHeight={`20px`}
          text={data.prismicStudio.data.studio_contact}
        />
        <Mailchimp />
        <TextBox
          fontSize={`15px`}
          lineHeight={`20px`}
          text={data.prismicStudio.data.internship_text}
        />

        <BottomText>
          <TextBox
            fontSize={`15px`}
            lineHeight={`20px`}
            text={data.prismicStudio.data.bottom_text}
          />
        </BottomText>
      </InnerColumn>
    </Flex>
  </ListColumn>
);
